import {
    all,
    call,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';
import {
    Action,
    combineReducers,
} from 'redux';
import moment from 'moment';
import { createType } from '../core';
import { redirectToLogin } from '../router';
import { addToastError } from '../toasts';
import { objectHandler } from '../object';

const DEFAULT_ACCESS_DENIED_MSG = 'Доступ запрещён. Пожалуйста, проверьте свои права доступа.';
const DEFAULT_NETWORK_ERROR_MSG = 'Ошибка подключения. Пожалуйста, повторите запрос позже.';
const DEFAULT_UNKNOWN_NETWORK_ERROR_MSG = 'В данный момент ведутся технические работы. Пожалуйста, повторите запрос позже.';

export const PREFIX = 'GLOBAL_API';

export class UnauthorizedRequestError extends Error {
    response?: any;

    constructor(message: string, response?: any) {
        super(message);
        this.name = 'UnauthorizedRequestError';
        this.response = response;
    }
}

export interface UndefinedNetworkErrorData {
    status: number;
    data?: any;
}

/**
 * @example
 * initialDelay + pow(attempNumber * stepDelay, delayFactor)
 */
export interface IProgressiveDelayOptions {
    initalDelay: number;
    stepDelay: number;
    delayFactor: number;
}

export interface IFetchAndSelectOptions {
    maxAttemptsCount: number;
    delayBetweenAttemptsInMs: undefined|number;
    delay: undefined|number|IProgressiveDelayOptions;
    stateUpdater: any;
}

export const DEFAULT_FETCH_AND_SELECT_OPTIONS: Required<IFetchAndSelectOptions> = {
    maxAttemptsCount: 1,
    delay: 100000, // 10 sec
    stateUpdater: undefined,

    // deprecating fields
    delayBetweenAttemptsInMs: undefined,
};

export function evaluateDelayFromConfig(attemptNumber: number, options: IFetchAndSelectOptions): number {
    // deprecating fields, keep for backward compatibility
    if (typeof options.delayBetweenAttemptsInMs === 'number') {
        return options.delayBetweenAttemptsInMs;
    }

    if (typeof options.delay === 'number') {
        return options.delay;
    }

    if (typeof options.delay === 'object') {
        const delayConfig: IProgressiveDelayOptions = options.delay;
        return delayConfig.initalDelay + ((attemptNumber - 1) * delayConfig.stepDelay)**delayConfig.delayFactor;
    }

    // default delay time is 10sec
    return 10000;
}

export type TApiMethodResponse<TResponse> = Promise<TResponse> | Generator<any, TResponse, any> | TResponse;

export interface INetworkState {
    cacheVersion: number;
}

export const networkStateHandler = objectHandler<INetworkState>(
    PREFIX, 'network_state', () => ({
        cacheVersion: 1,
    })
);

export const CLEAR_FETCH_STATE = createType(PREFIX, 'CLEAR_FETCH_STATE');
export const UNDEFINED_NETWORK_ERROR = createType(PREFIX, 'UNDEFINED_ERROR');

export interface IClearGlobalFetchStateOptions {
    /**
     * Список идентификаторов обработчиков, которые не должны быть очищены.
     */
    ignoreHandlerIds?: string[];
    /**
     * Список тегов кэша, которые не должны быть очищены.
     */
    ignoreCacheTags?: string[];
}

export function isClearRequiredForHandler(id: string, networkOptions: Partial<NetworkOptions>, clearOptions: IClearGlobalFetchStateOptions): boolean {
    const {
        ignoreHandlerIds,
        ignoreCacheTags,
    } = clearOptions ?? {};

    // Если текущий хендлер в списке игнорируемых, то не очищаем состояние
    if (ignoreHandlerIds?.includes(id)) {
        return false;
    }

    // Если текущий хендлер имеет тег кеширования из списка игнорируемых для очищения, то не очищаем состояние
    if (ignoreCacheTags && networkOptions?.cacheTag) {
        // Если указан только один тег
        if (typeof networkOptions.cacheTag === 'string') {
            if (ignoreCacheTags.includes(networkOptions.cacheTag)) {
                return false;
            }
        } else {
            // Проверяем группе тегов кеширования хендлера, если указано несколько
            const hasAnyIgnoredCacheTag = networkOptions.cacheTag.some((tag) => ignoreCacheTags.includes(tag));
            if (hasAnyIgnoredCacheTag) {
                return false;
            }
        }
    }

    return true;
}

export type ClearGlobalFetchStatesActionType = Action & {
    options: IClearGlobalFetchStateOptions;
}

export const clearGlobalFetchStates = (options: IClearGlobalFetchStateOptions = {}): ClearGlobalFetchStatesActionType => ({
    type: CLEAR_FETCH_STATE,
    options,
});

export const undefinedNetworkError = (status: number, data?: any) => ({
    type: UNDEFINED_NETWORK_ERROR,
    status,
    data,
});

function* clearFetchStateSaga() {
    const state: INetworkState = yield select(networkStateHandler.selector);

    yield put(networkStateHandler.update({
        cacheVersion: (state?.cacheVersion || 1) + 1,
    }));
}

export type FetchingState = 'new'|'fetching'|'ready';

export type ApiErrorHandlerOptions = {
    /**
     * Включает игнорирование 401 и 403 ошибок от сервера.
     * По-умолчанию, отключено и при возникновении ошибки триггерится событие `REDIRECT_TO_LOGIN`.
     */
    skipAccessDeniedHandling: boolean;
    /**
     * Позволяет отключить всплывающие уведомления о возникновении сетевых ошибок.
     * Установите `false` чтобы отключить уведомления.
     */
    allowFeedback: boolean;
}

export interface NetworkOptions {
    /**
     * Идентификаторы кэша, которые назначены на хендлер.
     */
    cacheTag?: string[]|string;
    /**
     * Предпочесть обновление состояния при очистке кэша, вместо полного сброса.
     */
    updateOnClear?: boolean;
    /**
     * Настройка поведения при возникновении ошибок.
     */
    errorHandler: Partial<ApiErrorHandlerOptions>;
}

const DefaultApiErrorHandlerOptions: ApiErrorHandlerOptions = {
    skipAccessDeniedHandling: false,
    allowFeedback: true,
};

export function* handleCommonApiErrors(error, options?: Partial<ApiErrorHandlerOptions>) {
    const evaluatedOptions = {
        ...DefaultApiErrorHandlerOptions,
        ...(options || {}),
    };

    const response = error?.response;
    if (response) {
        // Handle all HTTP and network errors
        if (response?.status === 403 || response?.status === 401) {
            if (evaluatedOptions.skipAccessDeniedHandling === true) {
                return response?.data;
            }

            yield put(redirectToLogin());
            throw error;
        }
    }

    // Throw back all non relevant errors
    throw error;
}

export class NetworkRequestStat {
    public startTime: number;

    public endTime?: number;

    constructor() {
        this.startTime = moment().valueOf();
    }

    reset() {
        this.startTime = moment().valueOf();
        this.endTime = undefined;
    }

    finish() {
        this.endTime = moment().valueOf();
    }

    getDuration(): number|undefined {
        if (!this.endTime) {
            this.endTime = moment().valueOf();
        }

        return this.endTime - this.startTime;
    }

    toString() {
        return `startTime=${this.startTime}; endTime=${this.endTime ?? 'n/a'}; duration=${this.getDuration() ?? '0'}ms`;
    }

    toJSON() {
        return {
            startTime: moment(this.startTime).toISOString(),
            endTime: moment(this.endTime).toISOString(),
            duration: this.getDuration(),
        };
    }
}

export class NetworkRequestError extends Error {
    stat: NetworkRequestStat;

    response?: any;

    cause?: Error;

    constructor(message: string, stat: NetworkRequestStat, response?: any, cause?: Error) {
        super(message);
        this.name = 'NetworkRequestError';

        this.stat = stat;
        this.response = response;
        this.cause = cause;
    }

    toJSON() {
        return {
            stat: this.stat,
            response: this.response,
            cause: this.cause,
        };
    }
}

export function logNetworkError(title: string, params: any, networkStat: NetworkRequestStat, error?, response?) {
    const exception = new NetworkRequestError(title, networkStat, response ?? error?.response, error);
    if (params) {
        console.error(exception, params);
    } else {
        console.error(exception);
    }
}

export function* handleCommonApiErrorsSafe(title: string, params: any, networkStat: NetworkRequestStat, error, options?: Partial<ApiErrorHandlerOptions>) {
    const evaluatedOptions = {
        ...DefaultApiErrorHandlerOptions,
        ...(options || {}),
    };

    const response = error?.response;
    if (response) {
        const status = response.status;

        if (status >= 500) {
            yield put(undefinedNetworkError(status, response.data));
        }

        if (status === 404) {
            // Not found error must be logged only as warn
            console.warn(`${title} not found [${status}]`, params, response.data);
            return true;
        }

        if (status === 403) {
            if (evaluatedOptions.skipAccessDeniedHandling === true || !evaluatedOptions.allowFeedback) {
                return;
            }
            // Handle forbidden error by showing a toast message
            yield put(addToastError(DEFAULT_ACCESS_DENIED_MSG));
            console.warn(`${title} access denied [${status}]`, params, response.data);
            return false;
        }

        if (status === 401) {
            if (evaluatedOptions.skipAccessDeniedHandling === true) {
                return;
            }

            console.warn(`${title} access denied [${status}]`, params, response.data);
            yield put(redirectToLogin());
            return false;
        }
    }

    logNetworkError(title, params, networkStat, error);
    // Всплывашки не нужны в регулярных запросах, обрабатывать стейт с ошибкой нужно отдельно в каждом кейсе
    // if (evaluatedOptions.allowFeedback) {
    //     if (error?.name === 'NetworkError') {
    //         yield put(addToastError(DEFAULT_NETWORK_ERROR_MSG));
    //     } else {
    //         yield put(addToastError(DEFAULT_UNKNOWN_NETWORK_ERROR_MSG));
    //     }
    // }

    return true;
}

export function* callApiSaga(apiMethod, errorHandlerOptions?: Partial<ApiErrorHandlerOptions>, ...params) {
    try {
        return yield call(apiMethod, ...params);
    } catch (error) {
        yield handleCommonApiErrors(error, errorHandlerOptions);
    }
}

export const reducer = combineReducers({
    ...networkStateHandler.reducerInfo,
});

export function* rootSaga() {
    yield all([

        takeEvery<any>(CLEAR_FETCH_STATE, clearFetchStateSaga),
    ]);
}
