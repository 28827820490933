import {
    call,
    delay,
    put,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import moment from 'moment';
import { DebounceRejectError } from '@core/async/debounce';
import { ApiDataResponse } from '@core/models/api';
import { createType } from '../core';
import {
    IBaseHandler,
    IFetchHandler,
    IFetchUpdateHandler,
    SimpleSelector,
} from '../props';
import { redirectToLogin } from '../router';
import {
    CLEAR_FETCH_STATE,
    ClearGlobalFetchStatesActionType,
    DEFAULT_FETCH_AND_SELECT_OPTIONS,
    evaluateDelayFromConfig,
    FetchingState,
    handleCommonApiErrorsSafe,
    IFetchAndSelectOptions,
    isClearRequiredForHandler,
    logNetworkError,
    NetworkOptions,
    NetworkRequestStat,
    TApiMethodResponse,
    UnauthorizedRequestError,
} from './common';

/**
 * Предоставляет доступ для работы с хендлером коллекций.
 * Для создания используйте {@link collectionApiHandler}
 *
 * @category Duck Handlers
 *
 * @see {@link fetchUpdateAndSelectFromCollection}
 * @see {@link selectFromCollection}
 * @see {@link collectionApiHandler}
 * @see {@link useCollectionApiHandler}
 */
export interface ICollectionApiHandler<TSource, TModel = any> extends IBaseHandler {
    /**
     * Уникальный идентификатор хендлера.
     */
    id: string;
    /**
     * @event
     * Срабатывает, когда данные были успешно загружены.
     * @param {TSource} source Ключ по которому данные были обновлены
     *
     * @example
     *
     * // ducks.ts
     * export const collectionHandlerInstance = collectionApiHandler(...);
     *
     * function* handleCollectionCompletedSaga({ source }) {
     *      // ....
     * }
     *
     * export function* subscriptions() {
     *      yield all([
     *          takeEvery(collectionHandlerInstance.FETCH_DONE, handleCollectionCompletedSaga)
     *      ]);
     * }
     */
    FETCH_DONE: string;
    /**
     * @event
     * Срабатывает, когда произошла ошибка во время загрузки данных
     * @param {TSource} source Ключ по которому данные не были загружены
     * @param {string} message Текстовое сообщение об ошибке
     * @param {Error} [error] Причина ошибки
     *
     * @example
     *
     * // ducks.ts
     * export const collectionHandlerInstance = collectionApiHandler(...);
     *
     * function* handleCollectionFailedSaga({ source, message, error }) {
     *      // ....
     * }
     *
     * export function* subscriptions() {
     *      yield all([
     *          takeEvery(collectionHandlerInstance.FETCH_ERROR, handleCollectionFailedSaga)
     *      ]);
     * }
     */
    FETCH_ERROR: string;

    /**
     * Запрашивает данные, используя кешированное значение по возможности.
     * Если данные были закешированы ранее, то повторного АПИ запроса не будет.
     *
     * @param source Ключ запроса данных
     * @param force Флаг для принудительного запроса данных из АПИ.
     */
    fetch(source: TSource, force?: boolean): any;
    /**
     * Запрашивает данные, игнорируя кеширование.
     * @param source Ключ запроса данных
     */
    fetchUpdate(source: TSource): any;
    /**
     * Обновляет полностью данные в кеше по определенному ключу.
     *
     * Используйте {@link reset} для сброса данных
     *
     * @param source Ключ запроса данных
     * @param payload Новое значение для записи в кеш.
     */
    update(source: TSource, payload: TModel): any;
    /**
     * Частичное обновление данных в кеше по ключу.
     * @param source Ключ запроса данных
     * @param payload Поля, которые необходимо обновить в кеше.
     */
    partialUpdate(source: TSource, payload: Partial<TModel>): any;
    /**
     * Полностью сбрасывает кеш хендлера.
     */
    reset(): any;
    /**
     * Сбрасывает кеш по конкретному ключу.
     * @param source Ключ запроса данных
     */
    reset(source: TSource): any;
    /**
     * Возвращает все закешированные данные хендлера
     * @group Selectors
     */
    selector: SimpleSelector<Record<TSource extends string|number ? TSource : string|number, TModel>>;
    /**
     * Возвращает закешированные данные определенного ключа
     * @group Selectors
     */
    itemSelector: (sourceKey: TSource) => SimpleSelector<TModel>;
    /**
     * Возвращает флаг, что данные загружаются для определенного ключа
     * @group Selectors
     */
    isItemFetching: (sourceKey: TSource) => SimpleSelector<boolean>;
    /**
     * Возвращает ошибку загрузки данных по определенному ключу
     * @group Selectors
     */
    itemFetchingError: (sourceKey: TSource) => SimpleSelector<string>;
    /**
     * Возвращает состояние загрузки данных по определенному ключу.
     * @group Selectors
     */
    itemFetchingState: (sourceKey: TSource) => SimpleSelector<FetchingState>;

    /**
     * Возвращает внутренее состояние хендлера
     * @group Selectors
     */
    internalSelector: SimpleSelector<Record<TSource extends string|number ? TSource : string|number, CollectionItemInternalState>>;

    /**
     * @group Helpers
     * @param source Ключ данных
     * Приводит хендлер к реализации интерфейсов {@link IFetchHandler} и {@link IFetchUpdateHandler}
     *  для возможности использования с другими функциями и хуками.
     */
    withKey(source: TSource): IFetchHandler & IFetchUpdateHandler;
    /**
     * Обновляет внутренние данные в кеше по определенному ключу.
     *
     * Используйте {@link reset} для сброса данных
     *
     * @param source Ключ запроса данных
     * @param payload Новое значение для записи в кеш.
     */
    updateInternal: (source: TSource, payload: Partial<CollectionItemInternalState>) => any;
}

export type CollectionApiHandlerOptions<TSource = any> = {
    reducerKey?: (source: TSource) => string|number;
}

export interface CollectionItemInternalState {
    fetching: boolean;
    ready: boolean;
    error?: string;
    startTime?: number;
    endTime?: number;
}

/**
 * @deprecated Should be replace with @see CollectionItemInternalState
 */
export type CollectionItemFetchingState = CollectionItemInternalState;

interface ICollectionApiHandlerStateInternal<TModel> {
    internal: { [source: string]: CollectionItemInternalState };
    data: { [source: string]: TModel };
}

type ForceActionParams<TSource> = {
    source: TSource;
    force?: boolean;
};

/**
 * Создает новый хендлер {@link ICollectionApiHandler} для работы с АПИ, требующий ключ для доступа данных.
 * Например, если требуется реализовать доступ к таким АПИ как `GET /news/${id}` или `GET /dashboards/${dashboardId}/properties/${propertyId}`
 *
 * @template TSource Тип определяющий ключ доступа, может быть `number`, `string` или любым объектом.
 * @template TModel Тип АПИ модели данных
 * @template TResponse Тип сырого АПИ ответа от запроса
 *
 * @param prefix Префикс duck модуля
 * @param actionName Имя хендлера
 * @param dataKey ??? Ключ доступа данных в Redux хранилище
 * @param apiMethod Функция для получения данных от АПИ, в качестве параметра получает переданный ключ данных
 * @param apiDataSelector Функция для получения модели данных от сырого АПИ запроса. Если не указано, то берется из поля `data`.
 * @param options Дополнительные параметры настройки хендлера
 * @param networkOptions Параметры определяющие сетевое поведение хендлера.
 * @returns Новый экземпляр хендлера {@link ICollectionApiHandler}, который можно использовать в компонентах и другой логике.
 *
 * @category Duck Handlers
 *
 * @see {@link useCollectionApiHandler}
 * @see {@link useIsApiFetching}
 * @see {@link simpleApiHandler}
 * @see {@link listApiHandler}
 *
 * @example
 * // Пример загрузки данных с ключом в виде идентификатора - числа (аналогично будет работать и со строкой)
 *
 * // service.ts
 * export async function apiGetNewsPage(newsId: number): Promise<ApiDataResponse<NewsPageApiModel>> {
 *      return await apiGetRequest(`/news/${newsId}`);
 * }
 *
 * // ducks.ts
 * export const newsPageHandler = collectionApiHandler(
 *      PREFIX, 'page', 'news_page',
 *      apiGetNewsPage
 * );
 *
 * // NewsPage.tsx
 * const [ page, isFetching ] = useCollectionApiHandler(newsPageHandler, newsId);
 *
 * @example
 * // Пример загрузки данных с составным ключом
 *
 * // service.ts
 * export type ReportColumnSourceType = {
 *      dashboardId: number;
 *      columnId: string;
 * }
 *
 * export async function apiGetReportColumnDetails({ dashboardId, columnId }: ReportColumnSourceType): Promise<ApiDataResponse<ReportColumnApiModel>> {
 *      return await apiGetRequest(`/dashboards/${dashboardId}/columns/${encodeUriComponents(columnId)}`);
 * }
 *
 * // ducks.ts
 * export const dashboardColumnDetailsHandler = collectionApiHandler(
 *      PREFIX, 'column_details', 'dashboard_column_details',
 *      apiGetReportColumnDetails,
 *      {
 *          // При использовании составного ключа, обязательно необходимо настроить функцию для
 *          //  преобразования составного ключа в строку
 *          reducerKey: ({ dashboardId, columnId }) => `${dashboardId}/${columnId}`,
 *      }
 * );
 *
 * // ReportColumn.tsx
 *  const [ columnConfig ] = useCollectionApiHandler(
 *      dashboardColumnDetailsHandler,
 *      // Нет необходимости мемоизировать составной ключ, хук корректно определяет изменение необходимого ключа
 *      {
 *          dashboardId,
 *          columnId,
 *      });
 */
export function collectionApiHandler<TSource = any, TModel = any, TResponse = ApiDataResponse<TModel>>(
    prefix: string,
    actionName: string,
    // TODO Необходимо переписать хендлер, убрав этот парметр, он выглядит лишним
    dataKey: string,
    apiMethod: (sourceKey: TSource, ...args: any) => TApiMethodResponse<ApiDataResponse<TModel>|TResponse>,
    // TODO Необходимо переписать хендлер и поместить данный параметр внутрь options
    apiDataSelector: (resp: TResponse) => TModel = (response: any) => response.data,
    options: CollectionApiHandlerOptions<TSource> = {},
    // TODO Необходимо переписать хендлер и разместить эти настройки внутрь обычного options
    networkOptions?: Partial<NetworkOptions>
): ICollectionApiHandler<TSource, TModel> {
    const id = `${prefix}/${actionName}`;
    const reducerKey = options?.reducerKey || ((source: TSource) => source?.toString());

    const FETCH_ACTION = createType(prefix, `FETCH_${actionName}`);
    const FETCH_DONE = createType(prefix, `FETCH_DONE_${actionName}`);
    const FETCH_ERROR = createType(prefix, `FETCH_ERROR_${actionName}`);
    const RESET = createType(prefix, `RESET_${actionName}`);
    const UPDATE_ACTION = createType(prefix, `UPDATE_${actionName}`);
    const PARTIAL_UPDATE_ACTION = createType(prefix, `PARTIAL_UPDATE_${actionName}`);
    const UPDATE_INTERNAL = createType(prefix, `INTERNAL_UPDATE_${actionName}`);

    const fetch = (source: TSource, force?: boolean) => ({
        type: FETCH_ACTION,
        source,
        force,
    });
    const fetchUpdate = (source: TSource) => ({
        type: FETCH_ACTION,
        source,
        force: true,
    });
    const fetchDone = (source: TSource) => ({
        type: FETCH_DONE,
        source,
    });
    const fetchError = (source: TSource, errorMessage: string, e?: Error) => ({
        type: FETCH_ERROR,
        source,
        message: errorMessage,
        error: e,
    });

    const reset = (source?: TSource) => ({
        type: RESET,
        source,
    });
    const update = (source, payload) => ({
        type: UPDATE_ACTION,
        source,
        payload,
    });
    const partialUpdate = (source, payload) => ({
        type: PARTIAL_UPDATE_ACTION,
        source,
        payload,
    });
    const updateInternal = (source, payload: Partial<CollectionItemInternalState>) => ({
        type: UPDATE_INTERNAL,
        source,
        payload,
    });

    const getDuck = state => state[prefix];
    const getSourceKey = (state, sourceKey: TSource): number|string => reducerKey(sourceKey);

    const selector = createSelector(getDuck, data => data[dataKey]?.data as Record<string|number, TModel>);
    const internalSelector = createSelector(getDuck, data => data[dataKey]?.internal as Record<string|number, CollectionItemInternalState>);

    const itemSelector = createSelector(
        [ selector, getSourceKey ],
        (data, sourceKey) => data?.[sourceKey]
    );

    const isItemFetching = createSelector(
        [ internalSelector, getSourceKey ],
        (state, sourceKey) => state?.[sourceKey]?.fetching
    );

    const itemFetchingState = createSelector(
        [ internalSelector, getSourceKey ],
        (state, sourceKey): FetchingState => {
            const itemState = state?.[sourceKey];
            if (itemState?.fetching) {
                return 'fetching';
            }

            if (itemState?.ready) {
                return 'ready';
            }

            return 'new';
        }
    );

    const itemFetchingError = createSelector(
        [ internalSelector, getSourceKey ],
        (state, sourceKey) => state?.[sourceKey]?.error
    );

    const withKey = (source: TSource): IFetchHandler & IFetchUpdateHandler => ({
        ready: state => isItemFetching(state, source),
        state: state => itemFetchingState(state, source),
        fetch: () => fetch(source),
        fetchUpdate: () => fetchUpdate(source),
    });

    const getInitialState = (): ICollectionApiHandlerStateInternal<TModel> => ({
        internal: {},
        data: {},
    });

    const reducer = (state = getInitialState(), {
        type,
        source,
        payload,
    }): ICollectionApiHandlerStateInternal<TModel> => {
        switch (type) {
        case RESET: {
            if (typeof source !== 'undefined') {
                const key = reducerKey(source);
                delete state.internal[key];
                delete state.data[key];

                return {
                    ...state,
                    internal: {
                        ...state.internal,
                    },
                    data: {
                        ...state.data,
                    },
                };
            }

            return getInitialState();
        }
        case UPDATE_INTERNAL: {
            const key = reducerKey(source);

            return {
                ...state,
                internal: {
                    ...state.internal,
                    [key]: {
                        ...(state.internal[key] || {}),
                        ...payload,
                    },
                },
            };
        }
        case UPDATE_ACTION: {
            if (typeof payload === 'undefined') {
                return getInitialState();
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [reducerKey(source)]: payload,
                },
            };
        }
        case PARTIAL_UPDATE_ACTION: {
            if (typeof payload === 'undefined') {
                return state;
            }

            const localState = state.data?.[reducerKey(source)] || {};

            return {
                ...state,
                data: {
                    ...state.data,
                    [reducerKey(source)]: {
                        ...localState,
                        ...payload,
                    },
                },
            };
        }
        default: {
            return state;
        }
        }
    };
    const reducerInfo = { [dataKey]: reducer };

    function* handleError(source, message: string, cause?: Error) {
        yield put(updateInternal(source, {
            fetching: false,
            error: message,
        }));
        yield put(fetchError(source, message, cause));
    }

    function* fetchSaga({
        source,
        force,
    }: ForceActionParams<TSource>) {
        const internalState: { [source: string]: CollectionItemInternalState } = yield select(internalSelector);

        const sourceKey = reducerKey(source);
        const isAlreadyFetching = internalState?.[sourceKey]?.fetching === true;
        const isReady = internalState?.[sourceKey]?.ready === true;

        if ((isReady || isAlreadyFetching) && !force) {
            return;
        }

        // perform API request
        const networkStat = new NetworkRequestStat();

        yield put(updateInternal(source, {
            fetching: true,
            error: undefined,
            startTime: networkStat.startTime,
            endTime: undefined,
        }));

        try {
            const resp = yield call(apiMethod, source);
            networkStat.finish();

            if (!resp) {
                yield put(updateInternal(source, { endTime: networkStat.endTime }));
                yield handleError(source, 'No response');
                logNetworkError(`FETCH COLLECTION ${id} empty response`, { source }, networkStat);
                return;
            }

            const payload = apiDataSelector(resp);
            yield put(update(source, payload));
            yield put(updateInternal(source, {
                fetching: false,
                ready: true,
                endTime: networkStat.endTime,
            }));

            if (resp.status === 'error') {
                console.warn(`FETCH COLLECTION ${id} (${source}) error: ${resp.error}`, resp);
                yield handleError(source, resp.error || 'Unknown API error');
                return;
            }

            yield put(fetchDone(source));
        } catch (e) {
            if (e instanceof DebounceRejectError) {
                return;
            }

            yield put(updateInternal(source, {
                fetching: false,
                endTime: moment().valueOf(),
            }));

            if (e instanceof UnauthorizedRequestError) {
                logNetworkError(`FETCH COLLECTION ${id} access denied`, { source }, networkStat, e, undefined);
                yield handleError(source, 'Access denied', e);
                yield put(redirectToLogin());
                return;
            }

            yield handleCommonApiErrorsSafe(`FETCH COLLECTION ${id} exception`, { source }, networkStat, e, networkOptions?.errorHandler);
            yield handleError(source, e.message || 'Unknown API error', e);
        }
    }

    function* clearStateSaga({ options: clearOptions }: ClearGlobalFetchStatesActionType) {
        if (!isClearRequiredForHandler(id, networkOptions, clearOptions)) {
            return;
        }

        yield put(reset());
    }

    const effects = [
        takeEvery(FETCH_ACTION, fetchSaga as any),
        takeEvery(CLEAR_FETCH_STATE, clearStateSaga),
    ];

    return {
        id,
        FETCH_DONE,
        FETCH_ERROR,
        fetch,
        fetchUpdate,
        update,
        partialUpdate,
        updateInternal,
        reset,
        selector,
        internalSelector,
        itemSelector: sourceKey => state => itemSelector(state, sourceKey),
        isItemFetching: sourceKey => state => isItemFetching(state, sourceKey),
        itemFetchingState: sourceKey => state => itemFetchingState(state, sourceKey),
        itemFetchingError: sourceKey => state => itemFetchingError(state, sourceKey),
        withKey,
        reducer,
        reducerInfo,
        effects,
    };
}

export type SourcedCollection<TSource extends number|string, TModel> = {
    [source in TSource]: TModel;
}

export function* fetchUpdateAndSelectFromCollection<TSource extends number|string, TModel>(collection: ICollectionApiHandler<TSource, TModel>, source: TSource, options?: Partial<IFetchAndSelectOptions>) {
    const opt = {
        ...DEFAULT_FETCH_AND_SELECT_OPTIONS,
        ...(options || {}),
    };

    let attemptNumber = 0;
    if (opt.stateUpdater) {
        yield call(opt.stateUpdater, {
            source,
            attemptNumber,
        });
    }

    while (attemptNumber < opt.maxAttemptsCount) {
        attemptNumber++;
        if (opt.stateUpdater) {
            yield call(opt.stateUpdater, {
                source,
                attemptNumber,
            });
        }

        yield put(collection.fetchUpdate(source));

        // В данном случае нестрогое равенство уместно в ввиду особенностей чтения параметров из роутов и АПИ структуры
        // eslint-disable-next-line eqeqeq
        const event = yield take(action => [ collection.FETCH_DONE, collection.FETCH_ERROR ].includes(action.type) && action.source == source);
        yield put(event);

        if (event.type === collection.FETCH_ERROR) {
            const error = event.error;
            if (error && error instanceof UnauthorizedRequestError) {
                throw error;
            }

            // TODO Throw error??? may use option
            const errorMessage = event.message || 'Unknown API error';

            if (attemptNumber < opt.maxAttemptsCount) {
                console.warn(`FetchAndSelectCollection(${collection.id}): [${attemptNumber}/${opt.maxAttemptsCount}] ${errorMessage}`);
                const delayDurationInMs = evaluateDelayFromConfig(attemptNumber, opt);
                yield delay(delayDurationInMs);
                continue;
            }

            // TODO Use custom error object
            throw new Error(errorMessage);
        }

        return yield selectFromCollection(collection, source);
    }
}

export function* selectFromCollection<TSource extends number|string, TModel>(collection: ICollectionApiHandler<TSource, TModel>, source: TSource) {
    if (typeof source === 'undefined' || source === null) {
        return undefined;
    }

    const models: SourcedCollection<TSource, TModel> = yield select<any>(collection.selector);

    return models?.[source] as TModel;
}
