import { createSelector } from 'reselect';
import {
    call,
    put,
    select,
    takeEvery,
    take,
    delay,
} from '@redux-saga/core/effects';
import {
    ActionCreatorType,
    createUpdateAction,
    createUpdateReducer,
} from '@core/store/actions';
import { DebounceRejectError } from '@core/async/debounce';
import { ApiDataResponse } from '@core/models/api';
import { createType } from '../core';
import {
    IBaseHandler,
    IFetchHandler,
    IFetchUpdateHandler,
    SimpleSelector,
} from '../props';
import {
    callApiSaga,
    CLEAR_FETCH_STATE,
    ClearGlobalFetchStatesActionType,
    DEFAULT_FETCH_AND_SELECT_OPTIONS,
    evaluateDelayFromConfig,
    FetchingState,
    handleCommonApiErrorsSafe,
    IFetchAndSelectOptions,
    isClearRequiredForHandler,
    logNetworkError,
    NetworkOptions,
    NetworkRequestStat,
    TApiMethodResponse,
    UnauthorizedRequestError,
} from './common';
import { redirectToLogin } from '../router';

/**
 * Simple API handler to perform requests to API and cache values to memory.
 * @category Duck Handlers
 */
export interface IApiHandler<TData> extends IBaseHandler, IFetchHandler, IFetchUpdateHandler {
    /**
     * Unique handler identifier.
     */
    id: string;

    /**
     * Redux action, triggered when request succesfully completed.
     * @event
     */
    FETCH_DONE: string;
    /**
     * Redux action, triggered when request failed with error.
     * @event
     */
    FETCH_ERROR: string;

    /**
     * Perform API request, prefer cached value.
     * If value already read from API, no any requests performed.
     */
    fetch: ActionCreatorType<ForceFetchState>;
    /**
     * Perform API request, ignoring cached values.
     */
    fetchUpdate: ActionCreatorType<ForceFetchState>;
    /**
     * Update cached value w/o API requests.
     * Use `undefined` value to clear cached value.
     */
    update: ActionCreatorType<FetchState<TData>>;
    partialUpdateSaga: any;

    /**
     * Selector to read current state value.
     * @group Selectors
     */
    selector: SimpleSelector<TData>;
    /**
     * Selector, returns the loading state of this handler.
     * @group Selectors
     */
    isFetching: SimpleSelector<boolean>;
    /**
     * Selector, returns flag that latest request done with error.
     * @group Selectors
     */
    isError: SimpleSelector<boolean>;
    /**
     * Selector, returns latest request error.
     * @group Selectors
     */
    error: SimpleSelector<string>;
}

type ForceFetchState = {
    force?: boolean;
}

type FetchState<T> = {
    data: T;
    status?: string;
    error?: string;
    fetching: boolean;
    ready: boolean;
}

/**
 * Create a new instance of simple API handler.
 *
 * @param prefix
 * @param key
 * @param apiMethod Reference or callback to direct API service method.
 * @param apiDataSelector Callback to read data from request. By default, read from `$.data` field of response.
 * @param initialData Provide custom initial data for cached value. By default, `null`.
 * @param networkOptions Customize API behaviour.
 * @returns
 *
 * @category Duck Handlers
 *
 * @see {@link IApiHandler}
 * @see {@link useSimpleApiHandler}
 * @see {@link useIsApiFetching}
 * @see {@link collectionApiHandler}
 * @see {@link listApiHandler}
 *
 * @example
 * // service.ts
 * export async function apiGetAccountInfo(): Promise<ApiDataResponse<AccountInfoApiModel>> {
 *      return await apiGetRequest('/account/info');
 * }
 *
 * // duck.ts
 * export const accountInfoHandler = simpleApiHandler(PREFIX, 'info', apiGetAccountInfo);
 *
 * // component.ts
 * const [ accountInfo, isFetching ] = useSimpleApiHandler(accountInfoHandler);
 */
export function simpleApiHandler<TModel = any, TResponse = ApiDataResponse<TModel>>(
    prefix: string,
    key: string,
    apiMethod: (...args: any[]) => TApiMethodResponse<ApiDataResponse<TModel>|TResponse>,
    apiDataSelector: (resp: TResponse) => TModel = (resp: any) => resp.data as TModel,
    // TODO Extract to handler options
    initialData: () => TModel = () => null,
    // TODO Merge with handler options
    networkOptions?: Partial<NetworkOptions>
): IApiHandler<TModel> {
    const id = `${prefix}/${key}`;
    const actionName = key.toUpperCase();

    const FETCH = createType(prefix, `FETCH_${actionName}`);
    const FETCH_DONE = createType(prefix, `FETCH_DONE_${actionName}`);
    const FETCH_ERROR = createType(prefix, `FETCH_ERROR_${actionName}`);
    const UPDATE = createType(prefix, `UPDATE_${actionName}`);

    const fetch = createUpdateAction<ForceFetchState>(FETCH);
    const fetchUpdate = () => fetch({ force: true });
    const fetchError = (errorMessage: string, e?: Error) => ({
        type: FETCH_ERROR,
        message: errorMessage,
        error: e,
    });

    const fetchDone = createUpdateAction(FETCH_DONE);
    const update = createUpdateAction<FetchState<TModel>>(UPDATE);

    const getDuck = state => state?.[prefix];
    const getData = createSelector<any, FetchState<TModel>>(getDuck, data => data?.[key]);

    const selector = createSelector(getData, data => data?.data);
    const isFetching = createSelector(getData, data => data?.fetching);
    const isError = createSelector(getData, data => data?.status === 'error' && data?.error?.length > 0);
    const error = createSelector(getData, data => data?.error);
    const ready = createSelector(getData, data => data?.ready);
    const state = createSelector<any, FetchingState>(getData, (data: FetchState<TModel>) => {
        if (data?.fetching) {
            return 'fetching';
        }

        if (data?.ready) {
            return 'ready';
        }

        return 'new';
    });

    const reducer = createUpdateReducer<FetchState<TModel>>(UPDATE, () => ({
        data: initialData(),
        status: undefined,
        error: undefined,
        fetching: false,
        ready: false,
    }));
    const reducerInfo = { [key]: reducer };

    function* fetchSaga({ value = {} }: any) {
        const {
            force = false,
        }: ForceFetchState = value;

        const alreadyFetching = yield select(isFetching as any);
        if (alreadyFetching && !force) {
            return;
        }

        // do not update if data is ready and force mode is off
        const isReady = yield select(ready as any);
        if (isReady && !force) {
            return;
        }

        // set fetching state
        yield put(update({
            fetching: true,
        }));

        // perform API request
        const networkStat = new NetworkRequestStat();

        try {
            const resp = yield call(callApiSaga, apiMethod, networkOptions?.errorHandler);
            networkStat.finish();

            if (!resp) {
                yield put(update({
                    status: 'error',
                    fetching: false,
                    error: 'No response',
                }));

                logNetworkError(`FETCH ${id} empty response`, undefined, networkStat);
                yield put(fetchError('No response'));
                return;
            }

            const data = apiDataSelector(resp);

            yield put(update({
                status: resp.status,
                data,
                error: resp?.error,
                fetching: false,
                ready: true,
            }));

            if (resp.status === 'error') {
                console.warn(`FETCH ${id} error: ${resp.error}`, resp);
                yield put(fetchError(resp.error || 'Unknown API error'));
                return;
            }
        } catch (e: any) {
            if (e instanceof DebounceRejectError) {
                return;
            }

            if (e instanceof UnauthorizedRequestError) {
                yield put(update({
                    fetching: false,
                    status: 'error',
                    // TODO Evaluate error from response or exception
                    error: 'Access denied',
                }));

                console.warn(`FETCH ${id} access denied`);
                yield put(fetchError('Access denied', e));
                yield put(redirectToLogin());
                return;
            }

            yield put(update({
                fetching: false,
                status: 'error',
                // TODO Evaluate error from response or exception
                error: 'Unknown API network error',
            }));

            yield handleCommonApiErrorsSafe(`FETCH ${id} exception`, undefined, networkStat, e, networkOptions?.errorHandler);
            yield put(fetchError(e.message || 'Unknown API network error', e));
            return;
        }

        // put DONE action
        yield put(fetchDone());
    }

    function* partialUpdateSaga(newData) {
        const currentState = yield select(getData);
        const newState = {
            ...currentState,
            data: {
                ...currentState?.data,
                ...newData,
            },
        };

        yield put(update(newState));
    }

    function* clearStateSaga({ options: clearOptions }: ClearGlobalFetchStatesActionType) {
        if (!isClearRequiredForHandler(id, networkOptions, clearOptions)) {
            return;
        }

        if (networkOptions?.updateOnClear) {
            yield put(fetchUpdate());
            return;
        }

        yield put(update(undefined));
    }

    const effects = [
        takeEvery(FETCH, fetchSaga),
        takeEvery(CLEAR_FETCH_STATE, clearStateSaga),
    ];

    return {
        id,
        FETCH_DONE,
        FETCH_ERROR,

        fetch,
        fetchUpdate,
        update,
        partialUpdateSaga,

        selector,
        ready,
        isFetching,
        isError,
        error,
        state,
        reducer,
        reducerInfo,
        effects,
    };
}

export function* fetchUpdateAndSelectHandler<TModel = any>(handler: IApiHandler<TModel>, options?: Partial<IFetchAndSelectOptions>) {
    const opt = {
        ...DEFAULT_FETCH_AND_SELECT_OPTIONS,
        ...(options || {}),
    };

    let attemptNumber = 0;
    if (opt.stateUpdater) {
        yield call(opt.stateUpdater, { attemptNumber });
    }

    while (attemptNumber < opt.maxAttemptsCount) {
        attemptNumber++;
        if (opt.stateUpdater) {
            yield call(opt.stateUpdater, { attemptNumber });
        }

        yield put(handler.fetchUpdate());
        const event = yield take([ handler.FETCH_DONE, handler.FETCH_ERROR ]);
        // Return event back to the queue to continue event handling by other handlers
        yield put(event);

        if (event.type === handler.FETCH_ERROR) {
            const error = event.error;
            if (error && error instanceof UnauthorizedRequestError) {
                throw error;
            }

            // TODO Throw error??? may use option
            const errorMessage = event.message || 'Unknown API error';

            if (attemptNumber < opt.maxAttemptsCount) {
                console.warn(`FetchAndSelect(${handler.id}): [${attemptNumber}/${opt.maxAttemptsCount}] ${errorMessage}`);
                const delayDurationInMs = evaluateDelayFromConfig(attemptNumber, opt);
                yield delay(delayDurationInMs);
                continue;
            }

            // TODO Use custom error object
            throw new Error(errorMessage);
        }

        // TODO Fix typings
        return yield select<any>(handler.selector);
    }
}

export function* fetchAndSelectHandler<TModel = any>(handler: IApiHandler<TModel>, options?: Partial<IFetchAndSelectOptions>): Generator<any, TModel, any> {
    const opt = {
        ...DEFAULT_FETCH_AND_SELECT_OPTIONS,
        ...(options || {}),
    };

    const data: TModel = yield select(handler.selector);
    if (data) {
        return data;
    }

    let attemptNumber = 0;
    if (opt.stateUpdater) {
        yield call(opt.stateUpdater, { attemptNumber });
    }

    while (attemptNumber < opt.maxAttemptsCount) {
        attemptNumber++;
        if (opt.stateUpdater) {
            yield call(opt.stateUpdater, { attemptNumber });
        }

        yield put(handler.fetchUpdate());
        const event = yield take([ handler.FETCH_DONE, handler.FETCH_ERROR ]);
        // Return event back to the queue to continue event handling by other handlers
        yield put(event);

        if (event.type === handler.FETCH_DONE) {
            // Data should be received, return it.
            return yield select(handler.selector);
        }

        if (event.type === handler.FETCH_ERROR) {
            const error = event.error;
            if (error && error instanceof UnauthorizedRequestError) {
                throw error;
            }

            // TODO Throw error??? may use option
            const errorMessage = event.message || 'Unknown API error';

            if (attemptNumber < opt.maxAttemptsCount) {
                console.warn(`FetchAndSelect(${handler.id}): [${attemptNumber}/${opt.maxAttemptsCount}] ${errorMessage}`);
                yield delay(opt.delayBetweenAttemptsInMs);
                continue;
            }

            // TODO Use custom error object
            throw new Error(errorMessage);
        }
    }

    return undefined;
}
