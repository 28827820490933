import { Reducer } from 'react';
import { createSelector } from 'reselect';
import {
    ActionCreatorType,
    createObjectReducer,
    createUpdateAction,
} from '@core/store/actions';
import { createType } from './core';
import {
    IBaseHandler,
    SimpleSelector,
} from './props';

/**
 * Implement a simple in-memory store. Useful when need to store any states across application.
 *
 * @category Duck Handlers
 */
export interface IObjectHandler<TState> extends Omit<IBaseHandler, 'effects'> {
    /**
     * Redux action to update state.
     * @event
     */
    UPDATE_ACTION: string;

    /**
     * Perform any partial update for handler state.
     * Use can pass `undefined` value to reset state of this handler to initial state.
     */
    update: ActionCreatorType<TState>;
    /**
     * Selector to extract whole state of this handler.
     * @group Selectors
     */
    selector: SimpleSelector<TState>;
    reducer: Reducer<TState, any>;
}

/**
 * @deprecated
 * Используйте @see {@link storeHandler} и @see {@link useStoreHandler}
 *
 * Implement a simple in-memory store. Useful when need to store any states across application.
 *
 * @param prefix
 * @param key
 * @param getInitialState Provide any initialization code
 * @returns
 *
 * @category Duck Handlers
 *
 * @see {@link IObjectHandler}
 * @see {@link useObjectHandler}
 *
 * @example
 * // duck.ts
 * export const appStateHandler = objectHandler<IAppState>(PREFIX, 'state', () => ({
 *      state: 'idle',
 * }));
 *
 * // App.tsx
 * const [ state, updateState ] = useObjectHandler(appStateHandler);
 */
export function objectHandler<TState = any>(prefix: string, key: string, getInitialState: () => TState = (): any => ({})): IObjectHandler<TState> {
    const actionName = key.toUpperCase();

    const UPDATE_ACTION = createType(prefix, `UPDATE_${actionName}`);
    const update = createUpdateAction<TState>(UPDATE_ACTION);

    const getDuck = state => state[prefix];
    const selector = createSelector<any, TState>(getDuck, data => data?.[key] as TState);

    const reducer = createObjectReducer<TState>(UPDATE_ACTION, getInitialState);
    const reducerInfo = { [key]: reducer };

    return {
        UPDATE_ACTION,
        update,
        selector,
        reducer,
        reducerInfo,
    };
}
