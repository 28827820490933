import { createType } from './core';

export const PREFIX = 'router';

export const REFRESH_PAGE = createType(PREFIX, 'REFRESH_PAGE');
export const REDIRECT_TO_LOGIN = createType(PREFIX, 'REDIRECT_TO_LOGIN');
export const REDIRECT_TO_HOME = createType(PREFIX, 'REDIRECT_TO_HOME');
export const REDIRECT_TO = createType(PREFIX, 'REDIRECT_TO');
export const REDIRECT_TO_EXTERNAL = createType(PREFIX, 'REDIRECT_TO_EXTERNAL');

export const LOGOUT = createType(PREFIX, 'LOGOUT');

export const refreshPage = () => ({ type: REFRESH_PAGE });
export const redirectToLogin = () => ({ type: REDIRECT_TO_LOGIN });
export const redirectToHome = () => ({ type: REDIRECT_TO_HOME });
export const logout = () => ({ type: LOGOUT });
export const redirectTo = (path: string) => ({
    path,
    type: REDIRECT_TO,
});
export const redirectToExternal = (path: string) => ({
    path,
    type: REDIRECT_TO_EXTERNAL,
});